.hero {
    background-image: linear-gradient(to bottom right, var(--color3), var(--color4));
    color: white;

    display: flex;
    
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 6rem;
    padding-bottom: 10rem;
}

.hero-image {
    height: 50vh;

    box-sizing: border-box;
    padding: 2rem;
}

.hero-image img {
    width: 100%;
    height: 100%;

    object-fit: contain;
}

.hero-text {
    padding: 1rem;

    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 1rem;
}

.hero-text h1 {
    margin: 0;
    padding: 0;

    font-size: 8rem;

    letter-spacing: 8rem;
    line-height: 8rem;
}

.hero-text h1 .Loki {
    display: flex;

    justify-content: center;
    align-items: center;

    gap: 2rem;
}

.hero-text h1 .LokiChar {
    display: inline-flex;
    
    width: 8rem;
    height: 8rem;
    
    font-size: 8rem;
    line-height: 8rem;

    text-align: center;
}

.hero-text h1 .LokiChar:empty::after {
    content: attr(value);
}

.hero-text p {
    margin: 0;
    padding: 0;

    font-size: 2rem;
}

.hero-text .type-text {
    font-weight: bold;
}



