* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    color: var(--color2);

    transition: all 0.3s ease-in-out;
}

.App {
    text-align: center;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: start;

    height: 100vh;

    gap: 1rem;
}