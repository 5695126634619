:root {
    --color1: #000000;
    --color2: #ffffff;
    --color3: #00ff99;
    --color4: #00bbff;
    --color5: #222222;
    --color6: #aaaaaa;
    --color7: #333333;
}

::-webkit-scrollbar {
    width: 1vw;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, var(--color3), var(--color4));
    border: 0.5vh transparent;
}

::-webkit-scrollbar-track {
    background-color: var(--color7);
}

::selection {
    background: var(--color3);
    color: var(--color1);
}