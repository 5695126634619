footer {
    background-color: var(--color1);

    text-align: center;

    margin: 0;
    padding: 0;

    width: 100%;
    height: 100vh;

    display: flex;

    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    z-index: 100001;

    position: relative;
}

.footer-line {
    border: 0;
    
    height: 0;
    width: 100%;

    background-image: linear-gradient(to bottom right, var(--color3), var(--color4));
}

.footer-content {
    width: 100%;

    margin: 0;
    padding: 1rem;

    display: flex;

    flex-direction: column;
    justify-content: end;
    align-items: center;

    gap: 10vh;

    height: 100%;
}

.footer-branding {
    font-size: 30vh;
    font-weight: bold;

    height: 30vh;
    width: 100%;

    overflow: hidden;

    letter-spacing: 0.5rem;

    color: var(--color2);

    display: flex;

    justify-content: center;
    align-items: center;

    opacity: 0.25;
}

.footer-branding .LokiChar {
    height: 30vh;
    width: 30vh;
    max-width: 30vh;

    display: inline-flex;

    justify-content: center;
    align-items: center;

    gap: 1rem;
}

.footer-text {
    font-size: 2rem;
    font-weight: bold;

    color: var(--color2);

    display: flex;

    justify-content: center;
    align-items: center;

    gap: 1rem;
}

.footer-text a {
    color: var(--color2);
    text-decoration: none;
}

