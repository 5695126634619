@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;700&display=swap');

.json-box {
    background-color: var(--color5);
    border-radius: 1rem;
    padding: 1rem;
    margin: 1rem 0;
}

.json-box pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: left;
    font-size: 1rem;
    font-family: 'JetBrains Mono', monospace;
}

