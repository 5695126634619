.gridlines::before {
    content: '';

    position: fixed;

    width: 100%;
    height: 1px;

    background-color: var(--color2);

    z-index: 1000;

    top: 50%;
    left: 0;
}

.gridlines::after {
    content: '';
    
    position: fixed;

    width: 1px;
    height: 100%;

    background-color: var(--color2);

    z-index: 1000;

    top: 0;
    left: 50%;
}

