#about {
    box-sizing: border-box;
}

#about h1 {
    color: var(--color2);
    font-size: 4rem;
    
    margin: 0;
}

#about p {
    color: var(--color2);
    font-size: 1.5rem;
    
    margin: 0;
}

#about .main-content {
    margin: 0;
    padding: 2rem;
}

#about .main-content p {
    font-size: 1.5rem;
    text-align: left;
    line-height: 2.5rem;
}

#about .filler-content {
    margin: 0;
    padding: 0 8rem;

    display: flex;

    justify-content: center;
}

#about .filler-content .json-box {
    width: 100%;
}

#about .filler-content .json-box pre {
    font-size: 1.25rem;
    font-family: 'JetBrains Mono', monospace;
}

#about .user-actions {
    margin: 0;
    padding: 2rem;

    display: flex;

    justify-content: center;
    align-items: center;

    gap: 2rem;
}

#about .user-actions button {
    margin: 0;
    padding: 1rem 2rem;

    font-size: 1.5rem;
    font-weight: bold;

    text-transform: capitalize;

    background-color: var(--color5);

    border: none;
    border-radius: 0.5rem;

    cursor: pointer;

    transition: all 0.2s ease-in-out;
}

#about .user-actions button:hover {
    scale: 1.1;
}

