.progress-bar {
    width: 100%;
    height: 1vh;
    
    min-height: 1vh;
    
    margin: 0;
    padding: 0;

    transition: all 0.5s ease-in-out;
}

.progress-bar-fill {
    height: 100%;
    width: 100%;

    transition: all 0.5s;

    margin: 0;
    padding: 0;
}

