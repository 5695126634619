.type-text {
    position: relative;
}

.type-text::after {
    content: "";

    width: 1ch;
    height: 10px;

    background: currentColor;

    position: absolute;
    
    top: 0%;
    left: 100%;

    margin: 0;
    padding: 0;

    height: 100%;

    animation: cursor-blink 1s infinite;
}

@keyframes cursor-blink {
    0%, 100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

