.gradient-text {
    background: linear-gradient(to bottom right, var(--color3), var(--color4));
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-text::selection {
    color: black;
    -webkit-text-fill-color: black;
}

