#cp {
    box-sizing: border-box;
}

#cp .section-content {
    width: 100%;
    box-sizing: border-box;
}

#cp .main-content {
    margin: 0;
    padding: 4rem;

    width: 100%;
}

#cp .leetcode-card {
    height: 70vh;
}

