section {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    padding-top: 2rem;
    
    width: 100%;
}

section:nth-child(2n) {
    background-color: var(--color5);
}

section:nth-child(2n + 1) {
    background-color: var(--color1);
}

section > h1 {
    color: var(--color2);
    font-size: 4rem;
    
    margin: 0;
}

.section:nth-child(2n):has(.filler-content):has(.main-content) .section-content {
    grid-template-areas: "main filler";
}

.section:nth-child(2n+1):has(.filler-content):has(.main-content) .section-content {
    grid-template-areas: "filler main";
}

.section:has(.filler-content):has(.main-content) .section-content {
    margin: 0;
    padding: 2rem;

    display: grid;

    grid-template-columns: 1fr 1fr;
}

.section:has(.filler-content):has(.main-content) .main-content {
    grid-area: main;
}

.section:has(.filler-content):has(.main-content) .filler-content {
    grid-area: filler;
}

