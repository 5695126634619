nav,
nav * {
    transition: all 0.5s ease;
}

nav {
    position: fixed;

    bottom: 5vh;
    left: 50%;

    transform: translateX(0%);

    width: 0%;
    height: 6vh;

    background-color: transparent;

    display: flex;
    align-items: center;

    gap: 0;

    margin: 0;
    padding: 0;
    padding-right: 2.5vh;

    border: none;
    border-radius: 3.5vh;
    
    z-index: 1000;
    
    cursor: default;
    user-select: none;
}

nav.active {
    width: 90%;

    transform: translateX(-50%);
    
    background-color: var(--color1);
    
    gap: 2.5vh;

    border: 0.5vh solid var(--color2);
}

.nav-container {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 0;

    margin: 0;
    padding: 0;

    overflow: hidden;
}

nav.active .nav-container {
    gap: 2.5vh;
    width: 100%;
    background-color: var(--color1);
}

nav button {
    height: calc(100% + 2.5vh);

    margin: 0;
    padding: 0.5rem;

    margin-right: -5vh;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 0.25rem solid var(--color2);
    border-radius: 50%;

    outline: none;

    aspect-ratio: 1;

    background-color: var(--color1);

    opacity: 0.2;
    cursor: pointer;

    transform: translateX(-50%);
}

nav button:hover,
nav.active button,
nav.active button:hover {
    opacity: 1;
}

nav button:hover {
    scale: 1.1;
}

nav button img {
    height: 100%;
    width: 100%;

    aspect-ratio: 1;

    object-fit: contain;
}

.navbar-left {
    display: flex;

    justify-content: start;
    align-items: center;
}

nav a:has(.gradient-text) {
    text-decoration: none;
}

nav a .gradient-text {
    font-size: 2.5vh;
    text-wrap: nowrap;
    letter-spacing: 0.5vh;

    font-weight: bold;

    width: 15vh;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

nav ul {
    height: 100%;

    display: flex;

    justify-content: center;
    align-items: center;

    gap: 2rem;

    margin: 0;
    padding: 1rem;

    box-sizing: border-box;
}

nav ul li {
    list-style: none;
}

nav ul li a {
    color: var(--color2);
    text-decoration: none;
    font-weight: bold;
    background-color: transparent;
    position: relative;
}

nav ul li a::after {
    content: '';
    
    display: block;
    
    width: 0;
    height: 0.5vh;
    
    background: linear-gradient(to bottom right, var(--color3), var(--color4));
    
    transition: width 0.5s;
    
    position: absolute;
    
    top: 100%;
    left: 0%;
}

nav ul li a:hover::after {
    width: 100%;
}

nav ul li a:has(svg, i)::after {
    display: none;
}

nav ul li a:hover svg {
    scale: 1.2;
}

nav .scroll-nav {
    font-size: 2vh;
}

.nav-notif {
    position: absolute;

    top: 0;
    left: calc(100%);
    
    height: 100%;

    display: flex;

    justify-content: center;
    align-items: center;

    background: var(--color1);
    color: var(--color2);

    padding: 0.5rem 1rem;
    margin: 0;

    box-sizing: border-box;

    border: 0.25rem solid var(--color2);
    border-radius: 1rem;
    overflow: visible;

    opacity: 0;
    transform: translateX(0);
    scale: 0.75;

    transition: all 0.5s;

    animation: none;

    cursor: pointer;
}

.nav-notif .gradient-text {
    font-size: 2vh;
    text-wrap: nowrap;
    letter-spacing: 0.5vh;

    font-weight: bold;

    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 1rem;
}

.nav-notif.active {
    opacity: 0.25;
    animation: notif-anim 2s infinite;
}

@keyframes notif-anim {
    10%, 30% {
        opacity: 0.25;
        transform: translateX(2.5vh);
    }

    0%, 20%, 40%, 100% {
        opacity: 0.5;
        transform: translateX(0);
    }
}

