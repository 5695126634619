.leetcode-card {
    display: flex;

    flex-direction: column;
    
    justify-content: space-between;
    align-items: center;

    gap: 1rem;
    
    margin: 0;
    padding: 1rem;

    border-radius: 0.5rem;

    box-shadow: none;
}

.leetcode-card-image {
    height: 100%;

    padding: 0.5rem;
    background: linear-gradient(to bottom right, var(--color3), var(--color4));

    border-radius: 0.5rem;

    display: flex;

    justify-content: center;
    align-items: center;

    overflow: hidden;
}

.leetcode-card-image img {
    width: 100%;
    height: 100%;

    background: transparent;
    
    object-fit: contain;

    position: relative;
}

.leetcode-link {
    text-decoration: none;

    display: flex;

    justify-content: center;
    align-items: center;

    gap: 1rem;
}

.leetcode-button {
    margin: 0;
    padding: 1rem;

    background-color: var(--color5);
    border-radius: 0.5rem;

    font-size: 1.5rem;
    font-weight: 500;

    display: flex;

    justify-content: center;
    align-items: center;

    gap: 1rem;

    border: none;

    cursor: pointer;

    font-weight: 700;
}

.leetcode-button:hover {
    scale: 1.1;
}

