#experience {
    box-sizing: border-box;
}

#experience .section-content {
    width: 100%;
    box-sizing: border-box;
}

#experience .main-content {
    margin: 0;
    padding: 4rem;

    width: 100%;
}

#experience .exp-list {
    width: 100%;

    list-style: none;

    display: flex;

    flex-direction: column;
    justify-content: start;
    align-items: center;

    gap: 2rem;
}

#experience .exp-list li {
    width: 100%;
}

.exp-card {
    width: 100%;
    box-sizing: border-box;

    display: flex;

    flex-direction: row;
    justify-content: start;
    align-items: start;

    gap: 2rem;
}

